import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import Moment from 'react-moment';

import { RoundInterfaces } from '@core/models/interfaces/roundInterfaces';
import { checkIsExpired } from '@core/utils/helpers/checkIsExpired';
import { toLocaleNumbers } from '@core/utils/helpers/toLocaleNumbers';
import { useAppSelector } from '@core/utils/hooks';
import { Badge } from '@components/MainComponents/Badge/Badge';
import { Button } from '@components/MainComponents/Button/Button';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { ReactComponent as ChevronDown } from '@assets/icons/arrows/chevron-down.svg';
import Pancakeswap from '@assets/img/pancakeswap.png';
import Unieth from '@assets/img/unieth.png';
import Uniarb from '@assets/img/uniarb.png';
import Unimatic from '@assets/img/unimatic.png';

import styles from './Steps.module.scss';

export const Step = ({
	data,
	onToggle,
	active
}: {
	data: RoundInterfaces;
	onToggle: () => void;
	active: boolean;
}) => {
	// Redux hooks
	const activeRoundNotStarted = useAppSelector(({ app }) => app.rounds.activeRoundNotStarted);

	// constants
	const displayStaticData = (roundName: string) => {
		switch (roundName) {
			case 'Round I':
				return { cliff: 'April 1', vesting: 'July 1', discount: '60%', allocation: 6000000 };
			case 'Round II':
				return { cliff: 'May 1', vesting: 'August 1', discount: '40%', allocation: 10000000 };
			case 'Round III':
				return { cliff: 'July 1', vesting: 'October 1', discount: '20%', allocation: 20000000 };
			case 'IDO':
				return { allocation: 16000000 };
		}
	};
	const EthNetwork = 'Ethereum';
	const BNBNetwork = 'Binance Smart Chain';
	const isExpiredStep = checkIsExpired(data.end_date);
	const IDOLinks = {
		unieth:
			'https://app.uniswap.org/#/swap?outputCurrency=0xe9b7b5d5e8d2bcc78884f9f9099bfa42a9e5c1a5',
		unimatic:
			'https://app.uniswap.org/swap?chain=polygon&outputCurrency=0x3a79241a92a4f06952107308057da1991792d372',
		uniarb:
			'https://app.uniswap.org/swap?chain=arbitrum&outputCurrency=0xa6099b214e8d069911702bc92ef274f63c476c5a',
		pancakeswap:
			'https://pancakeswap.finance/swap?outputCurrency=0x382ec3f9f2e79b03abf0127f3aa985b148cef6d7&chain=bsc'
	};

	if (data.name === 'IDO')
		return (
			<div className={styles.step}>
				<Button className={styles.title} onClick={onToggle}>
					<Heading tag='h6' className='h6'>
						{data.name}
					</Heading>
					<SvgIcon Icon={ChevronDown} className={cn({ [styles.notActive]: !active })} />
				</Button>
				<AnimateHeight height={active ? 'auto' : 0} duration={600} className={cn(styles.content)}>
					<span className={cn('subtitle', styles.contentItem)}>
						<p>Token price</p>
						<p>${data.price_per_token}</p>
					</span>
					<div className={styles.swapContainer}>
						<p className={'subtitle'}>IDO listing</p>
						<div className={styles.swapBlocks}>
							<Button as='externalLink' href={IDOLinks.unieth} className={styles.swapBlock}>
								<Heading tag='h6' className={'h6'}>
									ZENF on Ethereum
								</Heading>
								<img src={Unieth} alt='uniswap' />
								<Badge className={cn(styles.badge, 'body-md')}>Buy</Badge>
							</Button>
							{/* <Button as='externalLink' href={IDOLinks.unimatic} className={styles.swapBlock}>
								<Heading tag='h6' className={'h6'}>
									ZENF on Polygon
								</Heading>
								<img src={Unimatic} alt='uniswap' />
								<Badge className={cn(styles.badge, 'body-md')}>Buy</Badge>
							</Button>
							<Button as='externalLink' href={IDOLinks.uniarb} className={styles.swapBlock}>
								<Heading tag='h6' className={'h6'}>
									ZENF On Arbitrum
								</Heading>
								<img src={Uniarb} alt='uniswap' />
								<Badge className={cn(styles.badge, 'body-md')}>Buy</Badge>
							</Button> */}
							<Button as='externalLink' href={IDOLinks.pancakeswap} className={styles.swapBlock}>
								<Heading tag='h6' className={'h6'}>
									ZENF on BSC
								</Heading>
								<img src={Pancakeswap} alt='pancakeswap' />
								<Badge className={cn(styles.badge, 'body-md')}>Buy</Badge>
							</Button>
						</div>
					</div>
				</AnimateHeight>
			</div>
		);

	return (
		<div className={styles.step}>
			<Button className={styles.title} onClick={onToggle}>
				<Heading tag='h6' className='h6'>
					{data.name}
				</Heading>
				<Badge
					color='secondary'
					text='sm'
					size='sm'
					className={cn(styles.badge, 'caption', { [styles.active]: data.is_active })}
				>
					{data.is_active ? (
						activeRoundNotStarted ? (
							<>
								Starts on <Moment format='MMMM DD'>{data.start_date}</Moment>
							</>
						) : (
							<>
								Ends on <Moment format='MMMM DD'>{data.end_date}</Moment>
							</>
						)
					) : isExpiredStep ? (
						<>
							Ended on <Moment format='MMMM DD'>{data.end_date}</Moment>
						</>
					) : (
						<>
							Starts on <Moment format='MMMM DD'>{data.start_date}</Moment>
						</>
					)}
				</Badge>
				<SvgIcon
					width='34'
					height='34'
					Icon={ChevronDown}
					className={cn({ [styles.notActive]: !active })}
				/>
			</Button>
			<AnimateHeight height={active ? 'auto' : 0} duration={600} className={cn(styles.content)}>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Start date</p>
					<p>
						<Moment format='MMMM DD'>{data.start_date}</Moment>
					</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>End date</p>
					<p>
						<Moment format='MMMM DD'>{data.end_date}</Moment>
					</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Cliff starts</p>
					<p>{displayStaticData(data.name)?.cliff}</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Vesting starts</p>
					<p>{displayStaticData(data.name)?.vesting}</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Discount</p>
					<p>{displayStaticData(data.name)?.discount}</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Token price</p>
					<p>${data.price_per_token}</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Network</p>
					<p>{data.name === 'Round II' ? BNBNetwork : EthNetwork}</p>
				</span>
				<span className={cn('subtitle', styles.contentItem)}>
					<p>Token allocation</p>
					<p>{displayStaticData(data.name)?.allocation.toLocaleString()}</p>
				</span>
				<span className={cn(styles.progress, 'subtitle-md', styles.contentItem)}>
					<p>Progress ${toLocaleNumbers(data.sold, true)}</p>
					<p>Left ${toLocaleNumbers(data.limit - data.sold, true)}</p>
					<span className='progressBar'>
						<span className='progressBarActive' style={{ width: `${data.sold_percentage}%` }} />
					</span>
				</span>
			</AnimateHeight>
		</div>
	);
};
