import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import {
	ArcElement,
	Chart,
	Chart as ChartJS,
	ChartTypeRegistry,
	Legend,
	Tooltip,
	TooltipItem
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import { useWindowSize } from '@core/utils/hooks/useWindowSize';
import { HoveredItemIndexType } from '@sections/Allocation/Widgets/Widgets.types';

import styles from './Widgets.module.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart = ({
	setItemIndex
}: // hoveredLegend
{
	setItemIndex: Dispatch<SetStateAction<HoveredItemIndexType>>;
	hoveredLegend: number;
}) => {
	// Custom hooks
	// const { width } = useWindowSize();

	// React hooks
	// const DoughnutRef = useRef<ChartJSOrUndefined>(undefined);
	// useEffect(() => {
	// 	if (DoughnutRef.current) {
	// DoughnutRef.current.data.datasets[0].hoverBackgroundColor = '#fff';
	// DoughnutRef.current.update();
	// DoughnutRef.current.updateHoverStyle();
	// console.log('DoughnutRef.current.data', DoughnutRef.current.data);
	// 	}
	// }, [hoveredLegend]);
	//
	// const changeDoughnutChartSize = useCallback(() => {
	// 	if (width < 1530) {
	// 		return { width: '507px', height: '507px' };
	// 	}
	//
	// 	DoughnutRef?.current && DoughnutRef?.current.update();
	// 	return { width: '755px', height: '755px' };
	// }, [width]);

	// console.log('changeDoughnutChartSize', { ...changeDoughnutChartSize() });

	const data = {
		labels: [
			'Marketing',
			'Development',
			'Presale',
			'Team',
			'Community',
			'IDO',
			'Early adopters',
			'Treasury'
		],
		datasets: [
			{
				data: [21, 18, 18, 15, 10, 8, 5, 5],
				backgroundColor: '#6EB1FF',
				borderRadius: 20,
				borderWidth: 0,
				hoverBackgroundColor: '#3C97FF',
				spacing: 20
			}
		]
	};

	const options = {
		tooltips: {
			enabled: false
		},
		plugins: {
			legend: { display: false },
			title: {
				display: true,
				text: 'Doughnut Chart',
				color: 'blue',
				font: {
					size: 44
				},
				padding: {
					top: 30,
					bottom: 30
				},
				responsive: true,
				animation: {
					animateScale: true,
					color: true
				}
			},
			tooltip: {
				backgroundColor: 'rgba(255, 255, 255, 1)',
				textAlign: 'center',
				bodyFont: { weight: 'bold', size: 26, family: 'Creato Display' },
				bodyColor: '#19192C',
				displayColors: false,
				caretPadding: 40,
				footerColor: '#535361',
				footerFont: { weight: 'bold', size: 16, family: 'Creato Display' },
				callbacks: {
					title: () => {
						return '';
					},
					label: (context: TooltipItem<keyof ChartTypeRegistry>) => {
						return context.formattedValue + '%';
					},
					footer: (context: TooltipItem<keyof ChartTypeRegistry>[]) => {
						return context[0].label;
					}
				}
			}
		},
		maintainAspectRatio: false,
		cutout: '88%'
	};

	const textCenter = {
		id: 'textCenter',
		beforeDatasetsDraw(chart: Chart): boolean | void {
			const { ctx } = chart;
			ctx.save();

			ctx.font = '600 26px Creato Display';
			ctx.fillStyle = '#19192C';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(
				'200 000 000 ZENF',
				chart.getDatasetMeta(0).data[0].x,
				chart.getDatasetMeta(0).data[0].y - 12
			);
			ctx.restore();

			ctx.font = '500 18px Creato Display';
			ctx.fillStyle = '#535361';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(
				'Total supply',
				chart.getDatasetMeta(0).data[0].x,
				chart.getDatasetMeta(0).data[0].y + 12
			);
			ctx.restore();
		}
	};

	const setActiveIndex = {
		id: 'setActiveIndex',
		afterDraw(chart: Chart) {
			const { tooltip } = chart;

			if (tooltip?.opacity) {
				if (tooltip?.dataPoints[0] && tooltip?.dataPoints[0].dataIndex >= 0)
					setItemIndex(tooltip?.dataPoints[0].dataIndex);
			} else {
				setItemIndex(-1);
			}
		}
	};

	return (
		<div className={styles.doughnutWrapper}>
			<Doughnut
				data={data}
				options={options}
				className={styles.doughnut}
				// ref={DoughnutRef}
				plugins={[textCenter, setActiveIndex]}
			/>
		</div>
	);
};
