import cn from 'classnames';

import { Heading } from '@components/MainComponents/Heading/Heading';

import styles from './Roadmap.module.scss';

export const Roadmap = () => {
	const stepsData: { title: string; list: string[]; status: 'completed' | 'in_progress' | '' }[] = [
		{
			title: 'Q3 2021',
			list: [
				'Zenland idea and brainstorming',
				'Smart contract escrow research',
				'Concept development',
				'Team buy-in discussions',
				'White-board user story mapping',
				'Resource building (bootstrapped)'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2021',
			list: [
				'MVP design and prototyping',
				'Sketching and wireframing',
				'Escrow smart contract form',
				'Contract control interface',
				'Contract chat interface',
				'UI/UX elements & brand kit'
			],
			status: 'completed'
		},
		{
			title: 'Q1 2022',
			list: [
				'Ethereum smart contract model',
				'Escrow logic implementation',
				'Dispute resolution logic',
				'User hypothesis testing',
				'Ethereum deployment',
				'Documentation v1.0'
			],
			status: 'completed'
		},
		{
			title: 'Q2 2022',
			list: [
				'MVP development',
				'Metamask wallet integration',
				'Backend integration',
				'5 EVM networks integration',
				'Official website launch',
				'Public beta release'
			],
			status: 'completed'
		},
		{
			title: 'Q3 2022',
			list: [
				'Multi-browser support',
				'Bug fixing and UI/UX optimization',
				'EVM compatibility tests',
				'Competitor analysis & research',
				'Marketing startegy',
				'Knowledge Base v.1.0'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2022',
			list: [
				'Phase I beta testing',
				'Escrow smart contract audit',
				'Zenland official release',
				'Lightpaper v.1.0',
				'Tokenomics research',
				'Mass adoption strategy'
			],
			status: 'completed'
		},
		{
			title: 'Q1 2023',
			list: [
				'TGE strategy development',
				'Whitepaper v.1.0',
				'Documentation v.2.0',
				'Phase II beta testing',
				'Token contract audit',
				'Token minting',
				'Ad/bounty campaigns'
			],
			status: 'completed'
		},
		{
			title: 'Q2 2023',
			list: [
				'Pre-approved model development',
				'User item listings/moderation',
				'Zenland mobile optimization',
				'Early adopters program',
				'Strategic L1, L2 partnerships',
				'ZENF token IDO'
			],
			status: 'completed'
		},
		{
			title: 'Q3 2023',
			list: [
				'Profile security and analytics ',
				'Full profile reputation analytics ',
				"Platform's multichain compatibility ",
				'Fraud report system',
				'Whitepaper v.2.0',
				'Niche marketing ads'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2023',
			list: [
				'Full EVM-compatibility',
				'7 more networks integration',
				'Custom network settings',
				'Support for more stablecoins/tokens',
				'Documentation v.3.0',
				'Multichannel marketing\n'
			],
			status: 'in_progress'
		}
	];

	return (
		<section className={styles.roadmap} id='roadmap'>
			<div className={cn(styles.steps, 'container', 'container-md')}>
				<div className={styles.step}>
					<Heading tag='h3' className={'h3'}>
						Roadmap
					</Heading>
					<p className={cn(styles.description, 'h6')}>
						ZENF token is a part of the Zenland project, building the only trustless smart contract
						escrow ecosystem for peer-to-peer transactions without a middleman. Roadmap milestones
						and deadlines are subject to change.
					</p>
				</div>
				{stepsData.map((data, i) => {
					return (
						<div className={styles.step} key={i}>
							<Heading
								tag='h5'
								className={cn('h5', {
									[styles.completed]: data.status === 'completed',
									[styles.inProgress]: data.status === 'in_progress'
								})}
							>
								{data.title}
							</Heading>
							<ul>
								{data.list.map((d, i) => {
									return (
										<li key={i} className='h6'>
											{d}
										</li>
									);
								})}
							</ul>
						</div>
					);
				})}
			</div>
		</section>
	);
};
