import { useEffect, useState } from 'react';

import { setActiveRoundNotStarted } from '@core/store/app/app.slice';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Step } from '@sections/Investment/Widgets/Steps/Step';

import styles from './Steps.module.scss';

export const Steps = () => {
	// Redux hooks
	const dispatch = useAppDispatch();

	// React hooks
	const [rounds, activeRound] = useAppSelector(({ app }) => [app.rounds.data, app.rounds.active]);
	const [clicked, setClicked] = useState<number>(-1);

	useEffect(() => {
		if (activeRound) {
			setClicked(activeRound.id);

			const isActiveRoundStarted =
				new Date(activeRound.start_date).getTime() - new Date().getTime();

			if (isActiveRoundStarted > 0) dispatch(setActiveRoundNotStarted(true));
			else dispatch(setActiveRoundNotStarted(false));
		}
	}, [activeRound]);

	// Handlers
	const handleToggle = (index: number) => {
		if (clicked === index) return setClicked(-1);
		setClicked(index);
	};

	return (
		<div className={styles.steps}>
			{rounds &&
				rounds.map(stepData => {
					return (
						<Step
							key={stepData.id}
							data={stepData}
							onToggle={() => handleToggle(stepData.id)}
							active={stepData.id === clicked}
						/>
					);
				})}
		</div>
	);
};
