import cn from 'classnames';

import { Button } from '@components/MainComponents/Button/Button';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { ReactComponent as ArrowRightTopIcon } from '@assets/icons/arrows/arrowRightTop.svg';

import styles from './Information.module.scss';

export const Information = () => {
	const totalSupply = 200000000;
	const marketCap = 10000000;

	return (
		<section id='information' className={styles.information}>
			<div className={cn(styles.container, 'container')}>
				<Heading tag='h3' className={cn('containerTitle', 'h3', styles.containerTitle)}>
					Token details
				</Heading>
				<div className={styles.grids}>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<Heading tag='h5' className='h5'>
							Ticker
						</Heading>
						<p className='h5'>ZENF</p>
					</div>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<Heading tag='h5' className='h5'>
							Decimals
						</Heading>
						<p className='h5'>18</p>
					</div>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<Heading tag='h5' className='h5'>
							Type
						</Heading>
						<p className='h5'>ERC-20</p>
					</div>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<div>
							<Heading tag='h5' className='h5'>
								Total supply
							</Heading>
							<p className='h5'>{totalSupply.toLocaleString()} ZENF</p>
						</div>
						<div>
							<Heading tag='h5' className='h5'>
								Circulating supply
							</Heading>
							<p className='h5'>N/A</p>
						</div>
						<div>
							<Heading tag='h5' className='h5'>
								Initial market cap
							</Heading>
							<p className='h5'>${marketCap.toLocaleString()}</p>
						</div>
					</div>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<Heading tag='h5' className='h5'>
							Audited
						</Heading>
						<Button
							as='externalLink'
							href='https://github.com/Credshields/Audit-Reports/blob/main/Zenland_SmartContract_Audit.pdf'
							className={cn(styles.menuLink, 'h5')}
						>
							Credshields
							<SvgIcon Icon={ArrowRightTopIcon} />
						</Button>
					</div>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<Heading tag='h5' className='h5'>
							Token model
						</Heading>
						<p className='h5'>Deflationary</p>
					</div>
					<div className={cn(styles.grid, 'shadowedBlock')}>
						<Heading tag='h5' className='h5'>
							Token contracts
						</Heading>
						<Button
							as='externalLink'
							href='https://etherscan.io/token/0xe9b7b5d5e8d2bcc78884f9f9099bfa42a9e5c1a5'
							className={cn(styles.menuLink, 'h5')}
						>
							ETH: 0xE9b7B5D5e8d2BCC78884F9F9099bfA42A9e5c1A5
							<SvgIcon Icon={ArrowRightTopIcon} />
						</Button>
						<Button
							as='externalLink'
							href='https://bscscan.com/token/0x382ec3f9f2e79b03abf0127f3aa985b148cef6d7'
							className={cn(styles.menuLink, 'h5')}
						>
							BSC: 0x382ec3f9f2e79b03abf0127f3aa985b148cef6d7
							<SvgIcon Icon={ArrowRightTopIcon} />
						</Button>
						<Button
							as='externalLink'
							href='https://polygonscan.com/token/0x3a79241a92a4f06952107308057da1991792d372'
							className={cn(styles.menuLink, 'h5')}
						>
							Polygon: 0x3a79241a92a4f06952107308057da1991792d372
							<SvgIcon Icon={ArrowRightTopIcon} />
						</Button>
						<Button
							as='externalLink'
							href='https://arbiscan.io/token/0xa6099b214e8d069911702bc92ef274f63c476c5a'
							className={cn(styles.menuLink, 'h5')}
						>
							Arbitrum: 0xa6099b214e8d069911702bc92ef274f63c476c5a
							<SvgIcon Icon={ArrowRightTopIcon} />
						</Button>
						<Button
							as='externalLink'
							href='https://docs.zen.land/fundamentals/zenf-token'
							className='h6'
						>
							Read more about bridged ZENF Token
							<SvgIcon Icon={ArrowRightTopIcon} />
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
};
